export default {
  fabric_shade: "Roletki tkaninowe",
  external_shutter: "Rolety zewnętrzne",
  fabric_shade_day_night: "Roletki dzień-noc",
  pleat: "Plisy",
  automation: "Automatyka",
  detail: "Detale",
  fixed_order: "FX",
  frame_mosquito_net: "Moskitiery Ramkowe",
  roll_mosquito_net: "Moskitiery Zwijane",
  slide_mosquito_net: "Moskitiery przesuwne",
  door_mosquito_net: "Moskitiery Drzwiowe",
  pleat_mosquito_net: "Moskitiery Plisowane"
};
