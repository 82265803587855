import fetchSlideMosquitoNet from "../helpers/fetchSlideMosquitoNet";
import SlideMosquitoNetPriceListForm from "../components/SlideMosquitoNetPriceListForm.vue";
import generateProperties from "../../../helpers/generateProperties";

export default {
    list: {
        permissions: ["slide_mosquito_net_price_list_read"],
        route: {
            path: "/slide_mosquito_net_price_lists/:slideMosquitoNetId/list",
            name: "slide_mosquito_net_price_lists_list"
        },
        breadcrumbs: [
            {
                route: () => ({ name: "slide_mosquito_nets_list" }),
                label: () => "Moskitiery przesuwne"
            },
            {
                route: ({ compData }) =>  compData && compData.object && compData.object.slideMosquitoNet ? {
                    name: "slide_mosquito_net_price_lists_list",
                    params: { slideMosquitoNetId: compData.object.slideMosquitoNet.id }
                } : {
                    name: "slide_mosquito_net_price_lists_list",
                    params: { slideMosquitoNetId: 1 }
                },
                label: ({ compData }) =>
                    compData && compData.object && compData.object.slideMosquitoNet ? `Cennik bazowy dla wersji "${compData.object.slideMosquitoNet.name}"` : ''
            },
        ],
        fields: [
            {
                field: "slideMosquitoNet.name",
                type: "text",
                label: "Nazwa moskitiery",
            },
            {
                field: "slideMosquitoNet.symbol",
                type: "text",
                label: "Kod moskitiety",
            }
        ],
        resource: "slide_mosquito_net_price_lists",
        url: ({ route }) =>
            `/slide_mosquito_nets/${route.params.slideMosquitoNetId}/slide_mosquito_net_price_lists?`
        +generateProperties([],['id'],false)
        +generateProperties(['slideMosquitoNet'],['id', 'name', 'symbol'],true)
        ,
        onCreated: fetchSlideMosquitoNet,
        rowActions: [
            {
                action: "edit",
                permissions: ["slide_mosquito_net_price_list_update"]
            }
        ]
    },
    update: {
        permissions: ["slide_mosquito_net_price_list_update"],
        route: {
            path: "/slide_mosquito_net_price_lists/:id/update",
            name: "slide_mosquito_net_price_lists_update"
        },
        resource: "slide_mosquito_net_price_lists",
        breadcrumbs: [
            {
                route: () => ({ name: "slide_mosquito_nets_list" }),
                label: () => "Moskitiery przesuwne"
            },
            {
                route: ({ compData }) => ({
                    name: "slide_mosquito_net_price_lists_list",
                    params: { slideMosquitoNetId: compData.object.slideMosquitoNet.id }
                }),
                label: ({ compData }) =>
                    `Cennik bazowy dla wersji "${compData.object.slideMosquitoNet.name}"`
            },
        ],
        onCreated: fetchSlideMosquitoNet,
        formComponent: SlideMosquitoNetPriceListForm,
        listRoute: ({ compData }) => ({
            name: "slide_mosquito_net_price_lists_list",
            params: {
                slideMosquitoNetId: compData.object.slideMosquitoNet.id
            }
        })
    }
};
