<template lang="pug">
div
  b-loading(:active="loading")
  .columns
    .column.is-three-fifths
      b-button(
            v-for="cat in availaibleCategories"
            @click="onProductTypeChange(cat.value)"
            :type="cat.value === selectedCategory ? `is-primary` : ''"
          ) {{cat.name}}
    .column.is-one-fifth
      b-button(
        :type="`is-warning`"
        @click="toggleProductionTimeBookingEditing"
      ) Rezerwacja czasu pracy
      b-button(
        :type="`is-success`"
        @click="toggleFixedOrderEditing"
      ) FX
    .column.is-one-fifth.has-margin-bottom
      .has-font-20.has-text-right {{ currentDateNameOfDay }}, {{ currentDateString }}
  .columns
    .column.is-paddingless
      .has-margin-bottom(v-for="(ordersPerDay, index) in ordersPerDate" :class="{'is-sunday': getNameOfDayByDateString(index, 'DD-MM-YYYY', true) === 'Niedziela', 'is-saturday': getNameOfDayByDateString(index, 'DD-MM-YYYY', true) === 'Sobota', 'is-free-day': isFreeDay(index)}")
        .columns.has-margin-left-3
          .column.is-2.has-max-width-200
            .columns.is-multiline
              .column.is-3
                .has-text-centered.has-text-weight-bold.has-font-10 {{ getFormattedDateStringFromString(index, "DD-MM-YYYY", "DD.MM") }}
                .has-text-centered.has-text-weight-bold.has-font-10 {{ getNameOfDayByDateString(index) }}
              .column(v-if="ordersPerDay.length === 0")
              .column.is-3(v-for="line in getOrdersFromDaySortedByProductionLine(ordersPerDay, index)" :style="{backgroundColor: Math.floor(getProductionTimeInDay(line)/60/60) >= 8 ? '#ff0200' : line.color}" )
                div(v-if="(getCountOrderItems(line.orders) !== 0 || line.bookings.length !== 0) && getNameOfDayByDateString(index, 'DD-MM-YYYY', true) !== 'Niedziela'" )
                  .has-text-centered.has-text-weight-bold.has-font-10 ({{getCountOrderItems(line.orders)}})
                  .has-text-centered.has-text-weight-bold.has-font-10 {{Math.floor(getProductionTimeInDay(line)/60/60)}}:{{Math.ceil(getProductionTimeInDay(line)/60%60) < 10 ? "0" : ""}}{{Math.ceil(getProductionTimeInDay(line)/60%60)}}h
          .divider.is-vertical
          .column
            draggable(v-if="getNameOfDayByDateString(index,'DD-MM-YYYY', true) !== 'Niedziela'" :filter="'.dragging-disabled'" :disabled="dayHasDisabledDrag(index)" class="columns is-multiline min-draggable-height-small" v-model="mergedDraggableData[index]" @change="onProductionMove($event, 'production', index)" :emptyInsertThreshold="0" :animation="animation" :group="{name: 'orders', put: !isFreeDay(index)}")
              OrderTile(
                v-for="order in ordersPerDay"
                :key="'order' + order.id"
                :order="order"
                :orderNumber="getFormattedOrderNumber(order.number)"
                :countOfOrderItems="getCountOfOrderItems(order)"
                :companyShortName="getCompanyShortName(order)"
                @show="showOrder"
                @edit="editOrder"
              )
              ProductionBookingTile(
                v-for="booking in productionTimeBookingPerDate[index]"
                :key="'booking' + booking.id"
                :booking="booking"
                @edit="editProductionBooking"
              )
            .columns(v-else)
              .column.is-centered
                .has-text-centered.has-font-20.has-text-weight-bold Tydzień {{ getWeekNumberByDateString(index) }}
        .divider
    .divider.is-vertical
    .column.is-one-fifth
      .sticky-column
        .columns
          .column.has-text-weight-bold
            .is-free-day Dzień wolny
            .is-saturday Sobota
            .is-sunday Niedziela
          .column
        .divider
        .columns
          .column.date-picker
            date-picker(@input="onDatePickerDateChange" v-model="dateSelectedByDatePicker" type="day" value-type="format" format="YYYY-MM-DD" placeholder="Idź do daty" :clearable="false")
          .column
            b-input(:value="dateDuration" @input="onDateDurationInput" type="number" :min="1" :max="62")
        .has-text-centered.has-text-weight-bold.has-margin-bottom Zaległe
        draggable(class="columns is-multiline min-draggable-height overdue-drag" :list="overdue" @change="onChange($event, 'overdue')" :emptyInsertThreshold="emptyInsertThreshold" :animation="animation" :group="{name: 'orders', put: false}")
          OrderTile(
            v-for="order in overdue"
            :key="order.id"
            :order="order"
            :orderNumber="getFormattedOrderNumber(order.number)"
            :countOfOrderItems="getCountOfOrderItems(order)"
            :companyShortName="getCompanyShortName(order)"
            @show="showOrder"
            @edit="editOrder"
          )
        .divider
        .has-text-centered.has-text-weight-bold.has-margin-bottom Do wykonania
        <!-- zablokowac mozliwos wrzucenia do todo i overdue -->
        draggable(:list="todo" class="columns is-multiline min-draggable-height" @change="onChange($event, 'todo')" :emptyInsertThreshold="emptyInsertThreshold" :animation="animation" group="orders")
          OrderTile(
            v-for="order in todo"
            :key="order.id"
            :order="order"
            :orderNumber="getFormattedOrderNumber(order.number)"
            :countOfOrderItems="getCountOfOrderItems(order)"
            :companyShortName="getCompanyShortName(order)"
            @show="showOrder"
            @edit="editOrder"
          )
  production-order-preview(:active="isModalActive" :order-id="orderId" @close="isModalActive=false")
  ProductionOrderEdit(:active="isEditOrderModalActive" :order-id="editOrderId" @close="onEditOrderClose")
  FixedOrderForm(:active="isFixedOrderFormActive" :fixed-order="selectedFixedOrder" @close="onEditFixedOrderClose")
  ProductionOrderLineSelector(:active="isSelectProductionLineModalActive" :order="selectProductionLineModel" @close="onLineSelectorClose")
  ProductionTimeBookingForm(:active="isProductionTimeBookingFormActive", :booking="selectedProductionTimeBooking" @close="onProductionTimeBookingFormClose")
  ConfirmOverrideTimeInProduction(:active="isConfirmActive", :booking="selectedProductionTimeBooking" :functionToConfirm="functionToConfirm" @close="isConfirmActive = false")
</template>

<script>
import draggable from "vuedraggable";
import http from "../../../http";
import DatePicker from "vue2-datepicker";
import generateProperties from "../../../helpers/generateProperties";
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import OrderTile from "./OrderTile";
import ProductionBookingTile from "./ProductionBookingTile";
import productTypeCodesByOrderCategory from "../../../helpers/productTypeCodesByOrderCategory";
import ProductionOrderLineSelector from "../../common/components/ProductionOrderLineSelector";
import ProductionOrderEdit from "../../common/components/ProductionOrderEdit";
import ProductionOrderPreview from "../../common/components/ProductionOrderPreview";
import ProductionTimeBookingForm from "../../common/components/ProductionTimeBookingForm";
import FixedOrderForm from "../../common/components/FixedOrderForm";
import moment from "moment";
import ConfirmOverrideTimeInProduction from "../../../plugins/app/modal/ConfirmOverrideTimeInProduction.vue";
import notify from "../../../helpers/notify";
import simpleClone from "../../../helpers/simpleClone";
import orderCategoryByProductTypeCode from "../../../helpers/orderCategoryByProductTypeCode";
import getOrderItemFieldNameByCategory from "../../../helpers/getOrderItemFieldNameByCategory";

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {
    ProductionOrderLineSelector,
    draggable,
    DatePicker,
    ProductionOrderPreview,
    ProductionOrderEdit,
    OrderTile,
    ProductionBookingTile,
    ProductionTimeBookingForm,
    FixedOrderForm,
    ConfirmOverrideTimeInProduction
  },
  data() {
    return {
      isProductionTimeBookingFormActive: false,
      isEditOrderModalActive: false,
      isSelectProductionLineModalActive: false,
      selectedProductionTimeBooking: null,
      selectProductionLineModel: null,
      selectedFixedOrder: null,
      isFixedOrderFormActive: false,
      editOrderId: null,
      isModalActive: false,
      orderId: null,
      selectedByDatePicker: "",
      selectedCategory: orderCategoryByProductTypeCode.RTK,
      availaibleCategories: [
        {name: "Roletki Tkaninowe", value: orderCategoryByProductTypeCode.RTK},
        {name: "Roletki Dzień-noc", value: orderCategoryByProductTypeCode.RDN},
        {name: "Rolety Zewnętrzne", value: orderCategoryByProductTypeCode.RZW},
        {name: "Moskitiery Ramkowe", value: orderCategoryByProductTypeCode.MSQ},
        {name: "Moskitiery Zwijane", value: orderCategoryByProductTypeCode.MZW},
        {name: "Moskitiery Plisowane", value: orderCategoryByProductTypeCode.MPL},
        {name: "Moskitiery przesuwne", value: orderCategoryByProductTypeCode.MPR},
        {name: "Moskitiery Drzwiowe", value: orderCategoryByProductTypeCode.MDR},
        {name: "Plisy", value: orderCategoryByProductTypeCode.PLI},
        {name: "Detale", value: orderCategoryByProductTypeCode.DET}
      ],
      emptyInsertThreshold: 80,
      animation: 150,
      loading: false,
      //new setup
      startDate: null,
      dateSelectedByDatePicker: null,
      dateDuration: 31,
      ordersPerDate: {},
      productionTimeBookingPerDate: {},
      todo: [],
      overdue: [],
      freeDays: [],
      mergedDraggableData: {},
      isConfirmActive:false,
      functionToConfirm: null
    };
  },
  methods: {
    onProductTypeChange(value) {
      this.selectedCategory = value;
      this.resetDataAndReload();
    },
    getOrdersFromDaySortedByProductionLine(ordersPerDayDay, index) {
      //zamowienia z jednego dnia(index to data)
      let ordersAndBookingSortedByLine = {};
      for (const idx in ordersPerDayDay) {
        const line = ordersPerDayDay[idx].productionLine;
        if (line) {
          if (!ordersAndBookingSortedByLine[line['@id']]){
            ordersAndBookingSortedByLine[line['@id']] = {
              color: line.lineColor,
              orders: [],
              bookings: [],
            };
          }
          ordersAndBookingSortedByLine[line['@id']].orders.push(ordersPerDayDay[idx]);
        }
      }
      //a tutaj rezerwacje czasu per dzien(od index czyli danego dnia by zgadzalo sie z zamow ieniami wyzej)
      if(!this.productionTimeBookingPerDate[index]) {
        return ordersAndBookingSortedByLine;
      }

      for (const idx in this.productionTimeBookingPerDate[index]) {
        const line = this.productionTimeBookingPerDate[index][idx].productionLine;
        if (line) {
          if (!ordersAndBookingSortedByLine[line['@id']]){
            ordersAndBookingSortedByLine[line['@id']] = {
              color: line.lineColor,
              orders: [],
              bookings: [],
            };
          }
          ordersAndBookingSortedByLine[line['@id']].bookings.push(this.productionTimeBookingPerDate[index][idx]);
        }
      }

      return ordersAndBookingSortedByLine;
    },
    async downloadOrdersBySetDate() {
      this.loading = true;
      const endDate = moment(this.startDate)
        .add(this.dateDuration - 1, "d")
        .format("YYYY-MM-DD");

      const category = `category=${this.selectedCategory}&`;

      let { data } = await http.get(
        `/orders?${category}status[]=production&productionStatus[]=end&productionStatus[]=in_progress&productionDate[after]=${this.startDate.format("YYYY-MM-DD")}&productionDate[before]=${endDate}` +
          generateProperties(
            [],
            [
              "id",
              "name",
              "number",
              "status",
              "productionStatus",
              "productionDate",
              "productionTime",
              "category",
              "productionLine",
              "quantity",
              "fitterProductionWorkers",
              "shipmentDate"
            ],
            true
          ) +
          generateProperties(["company"], ["id", "shortName"], true) +
          generateProperties(["subCompany"], ["id", "shortName"], true) +
          generateProperties(
            [this.orderItemsFieldName],
            ["id", "quantity"],
            true
          ) +
          this.propertiesByCategory
      );
      const orders = data["hydra:member"];
      this.originalData = orders;
      for (const idx in orders) {
        this.pushOrderToListByProductionDate(orders[idx]);
      }
      this.loading = false;
    },
    async downloadProductionTimeBookingBySetDate() {
      this.loading = true;
      const endDate = moment(this.startDate)
        .add(this.dateDuration - 1, "d")
        .format("YYYY-MM-DD");

      const productTypeCodeByCategory = productTypeCodesByOrderCategory[this.selectedCategory];

      let { data } = await http.get(
        `/production_time_bookings?productType.code=${productTypeCodeByCategory}&productionDate[after]=${this.startDate.format("YYYY-MM-DD")}&productionDate[before]=${endDate}` +
          generateProperties(
            [],
            [
              "id",
              "description",
              "number",
              "productionTime",
              "productionDate",
              "productionLine",
              "productType",
              'shipmentDate',
              'productionStatus'
            ],
            true
          ) +
          generateProperties(
            [
              'productionLine'
            ],
            [
              'id',
              'lineColor',
              'fittersCount'
            ],
            true
          ) +
          generateProperties(
            ['company'],
            ['id', 'shortName'],
            true
          )
      );

      const productionTimeBookings = data["hydra:member"];

      for (const idx in productionTimeBookings) {
        this.pushProductionTimeBookingToListByProductionDate(productionTimeBookings[idx]);
      }

      this.loading = false;
    },
    resetOrdersPerDate() {
      let firstDay = moment(this.startDate);
      this.ordersPerDate = {};

      for (let i = 1; i <= this.dateDuration; i++) {
        this.ordersPerDate[firstDay.format("DD-MM-YYYY")] = [];
        firstDay.add(1, "d");
      }
    },
    resetProductionTimeBookingPerDate() {
      let firstDay = moment(this.startDate);
      this.productionTimeBookingPerDate = {};

      for (let i = 1; i <= this.dateDuration; i++) {
        this.productionTimeBookingPerDate[firstDay.format("DD-MM-YYYY")] = [];
        firstDay.add(1, "d");
      }
    },
    onProductionTimeBookingFormClose() {
      this.isProductionTimeBookingFormActive = false;
      this.resetDataAndReload();
    },
    toggleProductionTimeBookingEditing() {
      this.selectedProductionTimeBooking = null;
      this.isProductionTimeBookingFormActive = true;
    },
    toggleFixedOrderEditing() {
      this.selectedFixedOrder = null;
      this.isFixedOrderFormActive = true;
    },
    onDatePickerDateChange(date) {
      if (date && !this.startDate.isSame(moment(date, "YYYY-MM-DD"))) {
        this.startDate = moment(date, "YYYY-MM-DD");
        this.resetDataAndReload();
      }
    },
    //NIE USUWAĆ AWAIT ANI ASYNC
    async resetDataAndReload(exclusions = []) {
      //reset tablicy zamowien gdzie index to data a wartosc to tablica zamowien
      if(!exclusions.includes('resetOrdersPerDate')) {
        this.resetOrdersPerDate();
      }
      //reset tablicy rezerwacji czasu produkcji gdzie index to data a wartosc to tablica rezerwacji
      if(!exclusions.includes('resetProductionTimeBookingPerDate')) {
        this.resetProductionTimeBookingPerDate();
      }
      //pobiera zamowienia w zakresie dat i dopisuje do odpowiedniego klucza onbiektu
      if(!exclusions.includes('downloadOrdersBySetDate')) {
        await this.downloadOrdersBySetDate();
      }
      //pobiera rezerwacje czasu pracy i dopisuje do odpowiedniego klucza
      if(!exclusions.includes('downloadProductionTimeBookingBySetDate')) {
        await this.downloadProductionTimeBookingBySetDate();
      }
      //pobieranie zamowien do wykonania
      if(!exclusions.includes('getOrdersToDo')) {
        this.getOrdersToDo();
      }
      //pobieranie zamowien opoznionych
      if(!exclusions.includes('getOverdueOrders')) {
        this.getOverdueOrders();
      }
      //pobieranie dni wolnych
      if(!exclusions.includes('downloadFreeDays')) {
        this.downloadFreeDays();
      }
      //łączymy zamówienia i rezerwacje w jedno na potrzebny modelu dla draggable w sekcji html
      //po dniach
      for(let idx in this.ordersPerDate) {
        //po konkretnych objektach w dniu
        this.mergedDraggableData[idx] = this.ordersPerDate[idx].concat(this.productionTimeBookingPerDate[idx]);
      }
    },
    dayHasDisabledDrag(date) {
      return moment(date, "DD-MM-YYYY").isBefore(moment(), "d");
    },
    getWeekNumberByDateString(date, format = "DD-MM-YYYY") {
      return moment(date, format).add(1, 'd').isoWeek();
    },
    getDayOfWeekNameByNumber(dayNumber, isFull = false) {
      switch (dayNumber) {
        case 1:
          return isFull ? "Poniedziałek" : "Pon.";
        case 2:
          return isFull ? "Wtorek" : "Wt.";
        case 3:
          return isFull ? "Środa" : "Śr.";
        case 4:
          return isFull ? "Czwartek" : "Czw.";
        case 5:
          return isFull ? "Piątek" : "Pt.";
        case 6:
          return isFull ? "Sobota" : "Sob.";
        case 7:
          return isFull ? "Niedziela" : "Niedz.";
      }
    },
    getNameOfDayByDateString(date, format = "DD-MM-YYYY", fullDayName = false) {
      const dayNumber = moment(date, format).isoWeekday();
      return this.getDayOfWeekNameByNumber(dayNumber, fullDayName);
    },
    async getOrdersToDo() {
      const category = `category=${this.selectedCategory}&`;

      let { data } = await http.get(
        `/orders?${category}status[]=production&productionStatus[]=waiting` +
          generateProperties(
            [],
            [
              "id",
              "name",
              "number",
              "status",
              "productionStatus",
              "productionDate",
              "productionTime",
              "category",
              "productionLine",
              "productType",
              "quantity",
              'shipmentDate'
            ],
            true
          ) +
          generateProperties(["company"], ["id", "shortName"], true) +
          generateProperties(["subCompany"], ["id", "shortName"], true) +
          generateProperties(
            [this.orderItemsFieldName],
            ["id", "quantity"],
            true
          ) +
          this.propertiesByCategory
      );
      this.todo = data["hydra:member"];
    },
    async getOverdueOrders() {
      const category = `category=${this.selectedCategory}&`;
      let { data } = await http.get(
        `/orders?${category}productionStatus[]=in_progress&productionDate[strictly_before]=${moment().format("YYYY-MM-DD")}` +
          generateProperties(
            [],
            [
              "id",
              "name",
              "number",
              "status",
              "productionStatus",
              "productionDate",
              "productionTime",
              "category",
              "productType",
              "quantity",
              'shipmentDate'
            ],
            true
          ) +
          generateProperties(["company"], ["id", "shortName"], true) +
          generateProperties(["subCompany"], ["id", "shortName"], true) +
          generateProperties(
            [this.orderItemsFieldName],
            ["id", "quantity"],
            true
          ) +
          generateProperties(["productionLine"], ["lineColor"], true) +
          this.propertiesByCategory
      );
      this.overdue = data["hydra:member"];
    },
    //Pobiera tylko te dni wolne, ktore sa na prawde potrzebne, bo po co wiecej?
    async downloadFreeDays() {

      const startDate = moment(this.startDate)
        .format("YYYY-MM-DD");

      const endDate = moment(this.startDate)
        .add(this.dateDuration - 1, "d")
        .format("YYYY-MM-DD");

      let { data } = await http.get(
          `/production_free_days?date[before]=${endDate}&date[after]=${startDate}` +
          generateProperties(
            [],
            ['name', 'date'],
            true
          )
        );

      this.freeDays = data['hydra:member'];
    },
    //czy dzien wolny na podstawie dnia w wybranym formacie i bazy dni wolnych w danym okresie
    isFreeDay(day) {

      if(!day) {
        return false;
      }

      //jakby sypalo bledami invalid date, to mozna smialo zmienic tutaj
      const freeDayDateFormat = 'DD-MM-YYYY';
      const dayDateFormat = 'DD-MM-YYYY hh:mm:ss';
      return this.freeDays.findIndex(freeDay => moment(freeDay.date, freeDayDateFormat).isSame(moment(day, dayDateFormat))) > -1;
    },
    //zwraca nazwe dnia wolnego po dacie o ile istnieje
    getFreeDayNameBydate(day) {
      if(!day) {
        return false;
      }

      //jakby sypalo bledami invalid date, to mozna smialo zmienic tutaj
      const freeDayDateFormat = 'DD-MM-YYYY';
      const dayDateFormat = 'DD-MM-YYYY hh:mm:ss';

      const dateObject = this.freeDays.find(freeDay => moment(freeDay.date, freeDayDateFormat).isSame(moment(day, dayDateFormat)));

      if(dateObject && dateObject.name) {
        return dateObject.name;
      }

      return '';
    },
    // lowerMonth() {
    //   this.moveMonth("lower");
    // },
    // higherMonth() {
    //   this.moveMonth("higher");
    // },
    async onProductionMove(event, status, idx) {
      if (event.added && event.added.element) {
        const element = event.added.element;
        //i znow walidacja :)
        //w ten dziki sposob zapinam sie na rezerwacje czasu produkcji, w else jest zamowienie
        if(element['@type'] === 'ProductionTimeBooking') {
          //walidacja

          element.productionDate = idx;

          let validationData = new FormData();
          validationData.append('productionTime', this.getProductionTime(element));
          validationData.append('productType',parseInt(element.productType.split('/')[2]));
          validationData.append('productionLine', element.productionLine.id);
          validationData.append('productionDate', element.productionDate);
          let { data } = await http.post(
              '/api/validate/production/time',
              validationData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Accept": "application/ld+json"
                }
              }
          );

          if(!data.status) {
            if(data.errors) {
              let erorrsText = '';
              for(let i = 0; i < data.errors.length; i++) {
                erorrsText+= `${data.errors[i].propertyPath}: ${data.errors[i].message} \n`;
              }

              this.functionToConfirm = async () => {
                await http.put(
                    `/production_time_bookings/${element.id}?`+generateProperties([],['id','productionTime', 'productType', 'productionLine', 'productionDate']),
                    element
                );
                this.resetDataAndReload(['resetOrdersPerDate', 'downloadOrdersBySetDate', 'getOrdersToDo', 'getOverdueOrders', 'downloadFreeDays']);
              }
              this.isConfirmActive = true
              return;
            }
          }

            await http.put(
              `/production_time_bookings/${element.id}?`+generateProperties([],['id','productionTime', 'productType', 'productionLine', 'productionDate']),
              element
            );
            this.resetDataAndReload(['resetOrdersPerDate', 'downloadOrdersBySetDate', 'getOrdersToDo', 'getOverdueOrders', 'downloadFreeDays']);
          } else {
            //order i odpalenie modala od edycji linii
            this.lastMoveIdx = idx;
            this.isSelectProductionLineModalActive = true;
            this.selectProductionLineModel = element;
          }
      }
    },
    onLineSelectorClose(order) {
      this.isSelectProductionLineModalActive = false;
      this.selectProductionLineModel = null;
      this.onChange(
        {
          added: {
            element: order
          }
        },
        "production",
        this.lastMoveIdx
      );
    },
    onEditFixedOrderClose(fixedOrder) {
        this.isFixedOrderFormActive = false
        this.selectedFixedOrder = null;
    },
    //fixed
    async onChange(movedData, type, day = null) {
      if (
        type === "production" &&
        day !== null &&
        movedData.added &&
        movedData.added.element
      ) {
        //tutaj do zrobienia walidacja jak w edycji rezerwacji czasu pracy

        let element = simpleClone(movedData.added.element);
        element.productionDate = moment(day, "DD-MM-YYYY").format("YYYY-MM-DD");
        element.productionStatus = "in_progress";

        const productionLine = element.productionLine['@id'] || element.productionLine;

        //walidacja
        let validationData = new FormData();
        validationData.append('productionTime', this.getProductionTime(element));
        validationData.append('productType', productTypeCodesByOrderCategory[element.category]);
        validationData.append('productionLine', parseInt(productionLine.split('/')[2]));
        validationData.append('productionDate', element.productionDate);


        let { data } = await http.post(
          '/api/validate/production/time',
          validationData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Accept": "application/ld+json"
            }
          }
        );

        if(!data.status) {
          if(data.errors) {
            let erorrsText = '';
            for(let i = 0; i < data.errors.length; i++) {
              erorrsText+= `${data.errors[i].propertyPath}: ${data.errors[i].message} \n`;
            }
            // notify(erorrsText, 'warning', 4000);
            this.functionToConfirm = async () => {
              await http.put(
                  `/orders/${element.id}?properties[]=id&properties[]=productionDate&properties[]=productionStatus&properties[]=productionLine&properties[]=productType`,
                  element
              );
              this.resetDataAndReload();
            }
            this.isConfirmActive = true
            return;
          }
        }

        await http.put(
            `/orders/${element.id}?properties[]=id&properties[]=productionDate&properties[]=productionStatus&properties[]=productionLine&properties[]=productType`,
            element
        );

        this.resetDataAndReload();

      } else if (
        type === "todo" &&
        day === null &&
        movedData.added &&
        movedData.added.element
      ) {
        //zamowienie wlatuje na todo wiec nie walidujemy, ale pomijamy rezerwacje czasu
        let element = movedData.added.element;
        if(element && element['@type'] === 'ProductionTimeBooking') {
          notify('Rezerwacji czasu pracu nie można umieścić w "Do wykonania"', 'warning');
        } else {
          element.productionDate = null;
          element.productionStatus = "waiting";
          element.status = "production";
          await http.put(
            `/orders/${element.id}?properties[]=id&properties[]=productionDate&properties[]=productionStatus&properties[]=productionLine`,
            element
          );
        }
        this.resetDataAndReload();
      } else {
        // console.log(movedData);
      }
    },
    pushOrderToListByProductionDate(order) {
      const date = moment(order.productionDate, "DD-MM-YYYY");
      if (!Array.isArray(this.ordersPerDate[date.format("DD-MM-YYYY")])) {
        this.ordersPerDate[date.format("DD-MM-YYYY")] = [];
      }
      this.ordersPerDate[date.format("DD-MM-YYYY")].push(order);
    },
    pushProductionTimeBookingToListByProductionDate(productionTimeBooking) {
      const date = moment(productionTimeBooking.productionDate, "DD-MM-YYYY");
      if (!Array.isArray(this.productionTimeBookingPerDate[date.format("DD-MM-YYYY")])) {
        this.productionTimeBookingPerDate[date.format("DD-MM-YYYY")] = [];
      }
      this.productionTimeBookingPerDate[date.format("DD-MM-YYYY")].push(productionTimeBooking);
    },
    getCompanyShortName(order) {
      if (order.company) {
        return order.company.shortName.substring(0,7);
      } else if (order.subCompany) {
        return order.subCompany.shortName.substring(0,7);
      }
      return "";
    },
    getProductionTime(object) {
      const { productionLine: { fittersCount = 0 } = {}, fitterProductionWorkers, productionTime = 0, category } = object;
      const workerCount = Array.isArray(fitterProductionWorkers) && fitterProductionWorkers.length > 0 ? fitterProductionWorkers.length : fittersCount;

      if ('FixedOrder' === object['@type']) {
          return workerCount > 0 ? productionTime / workerCount : 0;
      }
      let time = 0;

      const productionTimeCategories = [
          orderCategoryByProductTypeCode.MZW,
          orderCategoryByProductTypeCode.MSQ,
          orderCategoryByProductTypeCode.MPL,
          orderCategoryByProductTypeCode.MDR,
          orderCategoryByProductTypeCode.MPR,
          orderCategoryByProductTypeCode.RTK,
          orderCategoryByProductTypeCode.RDN,
          orderCategoryByProductTypeCode.RZW,
          orderCategoryByProductTypeCode.PLI,
          orderCategoryByProductTypeCode.DET,
      ];

      if (productionTimeCategories.includes(category)) {
        time = productionTime;
      } else if(typeof object === "object") {
        if(Object.prototype.hasOwnProperty.call(object, 'productionTime')) {
          time = productionTime;
        }
      }

      return workerCount > 0 ? time / workerCount : 0;

      return time / fittersCount;
    },
    /**
     *
     * @param {Array bookings, Array orders} row
     */
    getProductionTimeInDay(line) {
      let time = 0;
      for (const idx in line.bookings) {
        const fittersCount = line.bookings[idx].productionLine.fittersCount
        time += this.getProductionTime(line.bookings[idx])
      }
      for (const idx in line.orders) {
        const fittersCount = line.orders[idx].productionLine.fittersCount
        time += this.getProductionTime(line.orders[idx])
      }

      return time;
    },
    getCountOrderItems(row) {
      let count = 0;
      for (const idx in row) {
        count += this.getCountOfOrderItems(row[idx]);
      }
      return count;
    },
    getFormattedOrderNumber(number) {
      return orderNumberCreator(number);
    },
    getFormattedDateStringFromString(date, inputFormat = "DD-MM-YYYY", outputFormat = "DD-MM-YYYY") {
      return moment(date, inputFormat).format(outputFormat);
    },
    getCountOfOrderItems(order) {
      if ('FixedOrder' === order['@type']) {
          return order.quantity;
      }
      const category = order.category;
      let count = 0;
      let itemsFieldName = null;
      if (category) {
        itemsFieldName = getOrderItemFieldNameByCategory(category);
      }

      if (itemsFieldName) {
        for (let i in order[itemsFieldName]) {
          count += order[itemsFieldName][i].quantity;
        }
      }

      return count;
    },
    showOrder(row) {
      this.orderId = row.id;
      this.isModalActive = true;
    },
    editOrder(order) {
      this.editOrderId = order.id;
      this.isEditOrderModalActive = true;
    },
    editProductionBooking(booking) {
      this.selectedProductionTimeBooking = booking;
      this.isProductionTimeBookingFormActive = true;
    },
    onEditOrderClose() {
      this.isEditOrderModalActive = false;
      this.selectedProductionTimeBooking = null;
      this.resetDataAndReload();
    },
    onDateDurationInput: debounce(function(value) {
      if (value <= 62 && value >= 1) {
        this.dateDuration = parseInt(value);
      } else if (value > 62) {
        this.dateDuration = 62;
      } else if (value < 1) {
        this.dateDuration = 1;
      }
      this.resetDataAndReload();
    }, 500)
  },
  computed: {
    currentDateNameOfDay() {
      return this.getDayOfWeekNameByNumber(moment().isoWeekday(), true);
    },
    currentDateString() {
      return moment().format("DD-MM-YYYY");
    },
    orderItemsFieldName() {
      return getOrderItemFieldNameByCategory(this.selectedCategory);
    },
    propertiesByCategory() {
      switch (this.selectedCategory) {
        case "fabric_shade":
          return '';
        case "fabric_shade_day_night":
          return '';
        case "external_shutter":
          return "";
        case "pleat":
          return "";
        case "detail":
          return "";
        case "frame_mosquito_net":
          return "";
        case "slide_mosquito_net":
          return "";
        case "pleat_mosquito_net":
          return "";
        case "door_mosquito_net":
          return "";
        case "roll_mosquito_net":
          return "";
        default:
          return "";
      }
    }
  },
  created() {
    //new setup
    this.startDate = moment().add(-1, 'day');
    this.dateSelectedByDatePicker = moment().add(-1, 'day').format("YYYY-MM-DD");
    this.resetDataAndReload();
  }
};
</script>
