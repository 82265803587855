import SlideMosquitoNetGlobalNetEntryForm from "../../slide_mosquito_nets/components/SlideMosquitoNetGlobalNetEntryForm.vue";
import fetchSlideMosquitoNet from "../helpers/fetchSlideMosquitoNet";
import generateProperties from "../../../helpers/generateProperties";

export default {
  list: {
    permissions: ["slide_mosquito_net_global_net_read"],
    actions: [
      {
        label: "Nowe wiązanie",
        type: "is-primary",
        route: ({ route }) => ({
          name: "slide_mosquito_net_global_net_entries_create",
          params: {
            slideMosquitoNetId: route.params.slideMosquitoNetId
          }
        }),
        permissions: ["slide_mosquito_net_global_net_create"]
      }
    ],
    route: {
      path: "/slide_mosquito_net_global_net_entries/:slideMosquitoNetId/list",
      name: "slide_mosquito_net_global_net_entries_list"
    },
    sortable: true,
    breadcrumbs: [
      {
        route: () => ({ name: "slide_mosquito_nets_list" }),
        label: () => "Moskitiery przesuwne"
      },
      {
        label: ({ compData }) =>
          `Wiązania kolorów siatek dla wersji "${compData.slideMosquitoNet.name}"`
      }
    ],
    fields: [
      {
        field: "globalNet.name",
        type: "text",
        label: "Nazwa siatki",
        filter: "text"
      },
      {
        field: "isEnabled",
        type: "boolean",
        label: "Aktywny",
        filter: "boolean"
      },
      {
        field: "skipCalculations",
        type: "boolean",
        label: "Pomiń kalkulacje cennikowe",
        filter: "boolean"
      },
    ],
    resource: "slide_mosquito_net_global_net_entries",
    url: ({ route }) =>
      `/slide_mosquito_nets/${route.params.slideMosquitoNetId}/slide_mosquito_net_global_net_entries?` +
        generateProperties([],['id', 'position', 'isEnabled', 'skipCalculations']) +
        generateProperties(['slideMosquitoNet'],['id', 'name', 'symbol'], true) +
        generateProperties(['globalNet'],['id','name','symbol'],true),
    onCreated: fetchSlideMosquitoNet,
    rowActions: [
      {
        action: "edit",
        permissions: ["slide_mosquito_net_global_net_update"]
      },
      {
        action: "remove",
        permissions: ["slide_mosquito_net_global_net_delete"]
      }
    ]
  },
  create: {
    permissions: ["slide_mosquito_net_global_net_create"],
    route: {
      path: "/slide_mosquito_net_global_net_entries/:slideMosquitoNetId/create",
      name: "slide_mosquito_net_global_net_entries_create"
    },
    breadcrumbs: [
      {
        route: () => ({ name: "slide_mosquito_nets_list" }),
        label: () => "Moskitiery przesuwne"
      },
      {
        route: ({ compData }) => ({
          name: "slide_mosquito_net_global_net_entries_list",
          params: { slideMosquitoNetId: compData.slideMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `kolory siatek dla wersji "${compData.slideMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Nowy kolor siatki dla wersji "${compData.slideMosquitoNet.name}"`
      }
    ],
    formComponent: SlideMosquitoNetGlobalNetEntryForm,
    onCreated: fetchSlideMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "slide_mosquito_net_global_net_entries_list",
      params: { slideMosquitoNetId: compData.slideMosquitoNet.id }
    }),
    updateRoute: ({ object , compData}) => ({
      name: "slide_mosquito_net_global_net_entries_update",
      params: {
        id: object.id,
        slideMosquitoNetId: compData.slideMosquitoNet.id
      }
    })
  },
  update: {
    permissions: ["slide_mosquito_net_global_net_entry_update"],
    route: {
      path: "/slide_mosquito_net_global_net_entries/:id/update",
      name: "slide_mosquito_net_global_net_entries_update"
    },
    resource: "slide_mosquito_net_global_net_entries",
    breadcrumbs: [
      {
        route: () => ({ name: "slide_mosquito_nets_list" }),
        label: () => "Moskitiery przesuwne"
      },
      {
        route: ({ compData }) => ({
          name: "slide_mosquito_net_global_net_entries_list",
          params: { slideMosquitoNetId: compData.object.slideMosquitoNet.id }
        }),
        label: ({ compData }) =>
          `Kolory siatek dla wersji "${compData.object.slideMosquitoNet.name}"`
      },
      {
        label: ({ compData }) =>
          `Edycja koloru dla siatki "${compData.object.globalNet.name}" z wersją "${compData.object.slideMosquitoNet.name}"`
      }
    ],
    formComponent: SlideMosquitoNetGlobalNetEntryForm,
    onCreated: fetchSlideMosquitoNet,
    listRoute: ({ compData }) => ({
      name: "slide_mosquito_net_global_net_entries_list",
      params: {
        slideMosquitoNetId: compData.object.slideMosquitoNet.id
      }
    })
  }
};
