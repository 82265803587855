<template>
  <b-menu class="box is-paddingless" :key="lastAttendanceDate">
    <b-menu-list label="Menu">
      <b-menu-item
          :label="item.label"
          v-for="item in allowedMenu"
          :key="item.label"
      >
        <b-menu-item
            v-for="child in allowedChildren(item.children)"
            :key="child.label"
            :icon="child.icon"
            :icon-pack="child.iconPack"
            :label="child.label"
            tag="router-link"
            :to="child.to"
        />
      </b-menu-item>
    </b-menu-list>
  </b-menu>


</template>
<script>
import {mapGetters} from "vuex";
import http from "../http";
import moment from "moment";

export default {
  data() {
    return {
      lastAttendanceDate: null
    };
  },
  computed: {
    ...mapGetters("auth", ["isAllowed"]),
    menu() {
      return [
        {
          label: "Inne",
          permissions: ["others"],
          children: [
            {
              icon: "newspaper",
              iconPack: "fas",
              label: "Aktualności",
              to: {
                name: "news_list"
              },
              permissions: ["news_read"]
            }
          ]
        },
        {
          label: "Zamówienia",
          permissions: ["order"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Zamówienia / Wyceny",
              to: {
                name: "orders_list",
                query: {
                  in: "new accepted production",
                },
              },
              permissions: ["orders_list"]
            },
            {
              icon: "bolt",
              iconPack: "fas",
              label: "Reklamacje zamówień",
              to: {
                name: "order_reclamations_list"
              },
              permissions: ["order_reclamations_read"]
            }
          ]
        },
        {
          label: "Roletki tkaninowe",
          permissions: ["fabric_shade", "window_frame", "fabric_shade_additionals"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "fabric_shades_list"
              },
              permissions: ["fabric_shade"]
            },
            {
              icon: "object-group",
              iconPack: "fas",
              label: "Grupy / Tkaniny",
              to: {
                name: "fabric_shade_groups_list"
              },
              permissions: ["fabric_shade"]
            },
            {
              icon: "crop-alt",
              iconPack: "fas",
              label: "Kolory okien",
              to: {
                name: "window_frames_list"
              },
              permissions: ["window_frame"]
            },
            {
              icon: "crop-alt",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "fabric_shade_additionals_list"
              },
              permissions: ["fabric_shade_additionals"]
            }
          ]
        },
        {
          label: "Roletki dzień-noc",
          permissions: ["fabric_shade_day_night", "window_frame_day_night"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "fabric_shade_day_nights_list"
              },
              permissions: ["fabric_shade_day_night"]
            },
            {
              icon: "object-group",
              iconPack: "fas",
              label: "Grupy / Tkaniny",
              to: {
                name: "fabric_shade_day_night_groups_list"
              },
              permissions: ["fabric_shade_day_night"]
            },
            {
              icon: "crop-alt",
              iconPack: "fas",
              label: "Kolory okien",
              to: {
                name: "window_frame_day_nights_list"
              },
              permissions: ["window_frame_day_night"]
            },
            {
              icon: "crop-alt",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "fabric_shade_day_night_additionals_list"
              },
              permissions: ["fabric_shade_day_night_additionals"]
            }
          ]
        },
        {
          label: "Rolety zewnętrzne",
          permissions: ["external_shutter"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "external_shutters_list"
              },
              permissions: ["external_shutter"]
            },
            {
              icon: "shield-alt",
              iconPack: "fas",
              label: "Pancerze",
              to: {
                name: "external_shutter_armors_list"
              },
              permissions: ["external_shutter_armor"]
            },
            {
              icon: "palette",
              iconPack: "fas",
              label: "Kolory pancerzy",
              to: {
                name: "external_shutter_armor_colors_list"
              },
              permissions: ["external_shutter_armor_color"]
            },
            {
              icon: "pause",
              iconPack: "fas",
              label: "Prowadnice",
              to: {
                name: "external_shutter_runners_list"
              },
              permissions: ["external_shutter_runner"]
            },
            {
              icon: "lock",
              iconPack: "fas",
              label: "Zabezpieczenia",
              to: {
                name: "external_shutter_protections_list"
              },
              permissions: ["external_shutter_protection"]
            },
            {
              icon: "circle",
              iconPack: "fas",
              label: "Rury",
              to: {
                name: "external_shutter_global_pipes_list"
              },
              permissions: ["external_shutter_global_pipes"]
            },
            {
              icon: "circle-notch",
              iconPack: "fas",
              label: "Nawierty",
              to: {
                name: "external_shutter_drill_holes_list"
              },
              permissions: ["external_shutter_drill_hole"]
            },
            {
              icon: "external-link-alt",
              iconPack: "fas",
              label: "Wyjścia napędów",
              to: {
                name: "external_shutter_drive_exits_list"
              },
              permissions: ["external_shutter_drive_exit"]
            }
          ]
        },
        {
          label: "Plisy",
          permissions: ["pleat", "pleat_fixing_type", "pleat_remote_control_type", "pleat_additionals"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "pleats_list"
              },
              permissions: ["pleat"]
            },
            {
              icon: "object-group",
              iconPack: "fas",
              label: "Grupy / Tkaniny",
              to: {
                name: "pleat_groups_list"
              },
              permissions: ["pleat"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Typy mocowań",
              to: {
                name: "pleat_fixing_types_list"
              },
              permissions: ["pleat_fixing_type"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Typy sterowań",
              to: {
                name: "pleat_remote_control_types_list"
              },
              permissions: ["pleat_remote_control_type"]
            },
            {
              icon: "palette",
              iconPack: "fas",
              label: "Kolory nici",
              to: {
                name: "thread_colors_list"
              },
              permissions: ["thread_colors"]
            },
            {
              icon: "crop-alt",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "pleat_additionals_list"
              },
              permissions: ["pleat_additionals"]
            }
          ]
        },
        {
          label: "Moskitiery ramkowe",
          permissions: ["frame_mosquito_nets"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "frame_mosquito_nets_list"
              },
              permissions: ["frame_mosquito_net"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "frame_mosquito_net_additionals_list"
              },
              permissions: ["frame_mosquito_net_additional"]
            },
          ]
        },
        {
          label: "Moskitiery zwijane",
          permissions: ["roll_mosquito_nets"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "roll_mosquito_nets_list"
              },
              permissions: ["roll_mosquito_net"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "roll_mosquito_net_additionals_list"
              },
              permissions: ["roll_mosquito_net_additional"]
            },
          ]
        },
        {
          label: "Moskitiery plisowane",
          permissions: ["pleat_mosquito_net"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "pleat_mosquito_nets_list"
              },
              permissions: ["pleat_mosquito_net"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "pleat_mosquito_net_additionals_list"
              },
              permissions: ["pleat_mosquito_net_additional"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Ramy",
              to: {
                name: "pleat_mosquito_net_frames_list"
              },
              permissions: ["pleat_mosquito_net_frame"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Progi",
              to: {
                name: "pleat_mosquito_net_sills_list"
              },
              permissions: ["pleat_mosquito_net_sill"]
            },
          ]
        },
        {
          label: "Moskitiery drzwiowe",
          permissions: ["door_mosquito_net"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "door_mosquito_nets_list"
              },
              permissions: ["door_mosquito_net"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "door_mosquito_net_additionals_list"
              },
              permissions: ["door_mosquito_net_additional"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Ramy",
              to: {
                name: "door_mosquito_net_frames_list"
              },
              permissions: ["door_mosquito_net_frame"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Progi",
              to: {
                name: "door_mosquito_net_bottom_rails_list"
              },
              permissions: ["door_mosquito_net_bottom_rail"]
            },
          ]
        },
        {
          label: "Moskitiery przesuwne",
          permissions: ["slide_mosquito_net"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Wersje",
              to: {
                name: "slide_mosquito_nets_list"
              },
              permissions: ["slide_mosquito_net"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Dodatki",
              to: {
                name: "slide_mosquito_net_additionals_list"
              },
              permissions: ["slide_mosquito_net_additional"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Ramy",
              to: {
                name: "slide_mosquito_net_frames_list"
              },
              permissions: ["slide_mosquito_net_frame"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Szyny dolne",
              to: {
                name: "slide_mosquito_net_bottom_rails_list"
              },
              permissions: ["slide_mosquito_net_bottom_rail"]
            },
          ]
        },
        {
          label: "Detale",
          permissions: ["detail", "detail_product", "detail_system", "detail_unit"],
          children: [
            {
              icon: "list",
              iconPack: "fas",
              label: "Lista",
              to: {
                name: "details_list"
              },
              permissions: ["detail"]
            },
            {
              icon: "atlas",
              iconPack: "fas",
              label: "Produkty",
              to: {
                name: "detail_products_list"
              },
              permissions: ["detail_product"]
            },
            {
              icon: "spinner",
              iconPack: "fas",
              label: "Systemy",
              to: {
                name: "detail_systems_list"
              },
              permissions: ["detail_system"]
            },
            {
              icon: "stream",
              iconPack: "fas",
              label: "Kategorie",
              to: {
                name: "detail_categories_list"
              },
              permissions: ["detail_category"]
            },
            {
              icon: "palette",
              iconPack: "fas",
              label: "Kolory",
              to: {
                name: "detail_colors_list"
              },
              permissions: ["detail_color"]
            },
            {
              icon: "list",
              iconPack: "fas",
              label: "Jednostki",
              to: {
                name: "detail_units_list"
              },
              permissions: ["detail_unit"]
            }]
        },
        {
          label: "Zarządzanie",
          permissions: [
            "company",
            "delivery_method",
            "payment_method",
            "country",
            "vat_rate",
            "user",
            "trait_color"
          ],
          children: [
            {
              icon: "address-book",
              iconPack: "fas",
              label: "Odbiorcy",
              to: {
                name: "companies_list"
              },
              permissions: ["company"]
            },
            {
              icon: "truck",
              iconPack: "fas",
              label: "Metody dostawy",
              to: {
                name: "delivery_methods_list"
              },
              permissions: ["delivery_method"]
            },
            {
              icon: "credit-card",
              iconPack: "fas",
              label: "Sposoby płatności",
              to: {
                name: "payment_methods_list"
              },
              permissions: ["payment_method"]
            },
            {
              icon: "globe",
              iconPack: "fas",
              label: "Kraje",
              to: {
                name: "countries_list"
              },
              permissions: ["country"]
            },
            {
              icon: "euro-sign",
              iconPack: "fas",
              label: "Waluty",
              to: {
                name: "currencies_list"
              },
              permissions: ["currency"]
            },
            {
              icon: "percent",
              iconPack: "fas",
              label: "Stawki VAT",
              to: {
                name: "vat_rates_list"
              },
              permissions: ["vat_rate"]
            },
            {
              icon: "user",
              iconPack: "fas",
              label: "Pracownicy",
              to: {
                name: "admin_workers_list"
              },
              permissions: ["admin_workers"]
            },
            {
              icon: "atlas",
              iconPack: "fas",
              label: "Typy produktów",
              to: {
                name: "product_types_list"
              },
              permissions: ["product_types"]
            },
            {
              icon: "hand-holding-usd",
              iconPack: "fas",
              label: "Cenniki detaliczne",
              to: {
                name: "retail_price_lists_list"
              },
              permissions: ["retail_price_lists"]
            },
            {
              icon: "truck-loading",
              iconPack: "fas",
              label: "Cenniki hurtowe",
              to: {
                name: "wholesale_price_lists_list"
              },
              permissions: ["wholesale_price_lists"]
            },

          ]
        },
        {
          icon: "address-book",
          iconPack: "fas",
          label: "Statystyki",
          permissions: ["statistic"],
          children: [
            {
              icon: "address-book",
              iconPack: "fas",
              label: "Ogólne",
              to: {
                name: "statistics_main"
              },
              permissions: ["statistic"]
            },
            {
              icon: "address-book",
              iconPack: "fas",
              label: "Wszyscy kontrahenci ",
              to: {
                name: "statistics_list"
              },
              permissions: ["statistic"]
            },
          ],
        },
        {
          label: "Globalne podzespoły",
          permissions: [
            "global_accessories",
            "global_fixings",
            "global_balks",
            "fabric_shade_color",
            "global_runners",
            "global_pipes",
            "global_cases",
            "global_drives",
            "global_drive_controls",
            "global_drive_types",
            "global_remote_controls",
            "global_clips",
            "global_nets"
          ],
          children: [
            {
              icon: "palette",
              iconPack: "fas",
              label: "Osprzęt",
              to: {
                name: "global_accessories_list"
              },
              permissions: ["global_accessories"]
            },
            {
              icon: "cog",
              iconPack: "fas",
              label: "Kasetki",
              to: {
                name: "global_cases_list"
              },
              permissions: ["global_cases"]
            },
            {
              icon: "wrench",
              iconPack: "fas",
              label: "Mocowania",
              to: {
                name: "global_fixings_list"
              },
              permissions: ["global_fixings"]
            },
            {
              icon: "minus",
              iconPack: "fas",
              label: "Belki dolne",
              to: {
                name: "global_balks_list"
              },
              permissions: ["global_balks"]
            },
            {
              icon: "pause",
              iconPack: "fas",
              label: "Prowadnice",
              to: {
                name: "global_runners_list"
              },
              permissions: ["global_runners"]
            },
            {
              icon: "circle",
              iconPack: "fas",
              label: "Rury",
              to: {
                name: "global_pipes_list"
              },
              permissions: ["global_pipes"]
            },
            {
              icon: "industry",
              iconPack: "fas",
              label: "Producenci",
              to: {
                name: "manufacturers_list"
              },
              permissions: ["manufacturers"]
            },
            {
              icon: "chevron-circle-right",
              iconPack: "fas",
              label: "Typy napędów",
              to: {
                name: "global_drive_types_list"
              },
              permissions: ["global_drive_types"]
            },
            {
              icon: "bus",
              iconPack: "fas",
              label: "Serie napędów",
              to: {
                name: "global_drive_series_list"
              },
              permissions: ["global_drive_series"]
            },
            {
              icon: "car",
              iconPack: "fas",
              label: "Napędy",
              to: {
                name: "global_drives_list"
              },
              permissions: ["global_drives"]
            },
            {
              icon: "keyboard",
              iconPack: "fas",
              label: "Typy sterowań",
              to: {
                name: "global_drive_control_types_list"
              },
              permissions: ["global_drive_control_types"]
            },
            {
              icon: "gamepad",
              iconPack: "fas",
              label: "Sterowania",
              to: {
                name: "global_drive_controls_list"
              },
              permissions: ["global_drive_controls"]
            },
            {
              icon: "spinner",
              iconPack: "fas",
              label: "Typy pilotów",
              to: {
                name: "global_remote_control_types_list"
              },
              permissions: ["global_remote_control_types"]
            },
            {
              icon: "rss",
              iconPack: "fas",
              label: "Kanały w pilotach",
              to: {
                name: "global_remote_control_channels_list"
              },
              permissions: ["global_remote_control_channels"]
            },
            {
              icon: "plane",
              iconPack: "fas",
              label: "Piloty",
              to: {
                name: "global_remote_controls_list"
              },
              permissions: ["global_remote_controls"]
            },
            {
              icon: "palette",
              iconPack: "fas",
              label: "Kolory osprzętu",
              to: {
                name: "fabric_shade_colors_list"
              },
              permissions: ["fabric_shade_color"]
            },
            {
              icon: "solar-panel",
              iconPack: "fas",
              label: "Siatki",
              to: {
                name: "global_nets_list"
              },
              permissions: ["global_net"]
            },
            {
              icon: "tape",
              iconPack: "fas",
              label: "Zaczepy",
              to: {
                name: "global_clips_list"
              },
              permissions: ["global_clip"]
            }
          ]
        },
        {
          label: "Cechy",
          permissions: ["trait_colors", "trait_fabrics"],
          children: [
            {
              icon: "palette",
              iconPack: "fas",
              label: "Kolory tkanin",
              to: {
                name: "trait_colors_list"
              },
              permissions: ["trait_colors"]
            },
            {
              icon: "palette",
              iconPack: "fas",
              label: "Cechy tkanin",
              to: {
                name: "trait_fabrics_list"
              },
              permissions: ["trait_fabrics"]
            }
          ]
        },
        {
          label: "Ustawienia",
          permissions: ["user_settings"],
          children: [
            {
              icon: "user",
              iconPack: "fas",
              label: "Użytkownik",
              to: {
                name: "user_settings"
              },
              permissions: ["user_settings", "support_instructions_read"]
            },
            {
              icon: "user-cog",
              iconPack: "fas",
              label: "Konfiguracja",
              to: {
                name: "configuration",
                params: {
                  id: 1
                }
              },
              permissions: ["configuration_read"]
            },
            {
              icon: "book",
              iconPack: "fas",
              label: "Instrukcje",
              to: {
                name: "support_instructions_list"
              },
              permissions: ["support_instructions_read"]
            },
            {
              icon: "download",
              iconPack: "fas",
              label: "Pliki do pobrania",
              to: {
                name: "download_sections_list"
              },
              permissions: ["download_sections_read"]
            },
            {
              icon: "exclamation-triangle",
              iconPack: "fas",
              label: "Uwagi do programu",
              to: {
                name: "bug_reports_list"
              },
              permissions: ["bug_reports_read"]
            },
          ]
        },
        {
          label: "Produkcja",
          permissions: ["production"],
          children: [
            {
              icon: "check",
              iconPack: "fas",
              label: "Obecność",
              to: {
                name: "attendance_register"
              },
              permissions: ["attendance_register"]
            },
            {
              icon: "calendar",
              iconPack: "fas",
              label: "Planowanie",
              to: {
                name: "production_plan"
              },
              permissions: ["production_plan"]
            },
            {
              icon: "users-cog",
              iconPack: "fas",
              label: "Produkcja",
              to: {
                name: "production_orders_list"
              },
              permissions: ["production_orders_list"]
            },
            {
              icon: "truck",
              iconPack: "fas",
              label: "Logistyka",
              to: {
                name: "logistic_list"
              },
              permissions: ["logistic_list"]
            },
            {
              icon: "users",
              iconPack: "fas",
              label: "Pracownicy prod.",
              to: {
                name: "production_workers_list"
              },
              permissions: ["production_workers"]
            },
            {
              icon: "calendar",
              iconPack: "fas",
              label: "Dni wolne",
              to: {
                name: "production_free_days_list"
              },
              permissions: ["production_free_days"]
            },
            {
              icon: "check",
              iconPack: "fas",
              label: "Linie produkcyjne",
              to: {
                name: "production_lines_list"
              },
              permissions: ["production_lines"]
            },
            {
                icon: "check",
                iconPack: "fas",
                label: "Akcje FX",
                to: {
                    name: "fixed_order_activities_list"
                },
                permissions: ["fixed_order_activities"]
            },
          ]
        },
        {
          label: "Aplikacja",
          permissions: ["app_error"],
          children: [
            {
              icon: "window-maximize",
              iconPack: "fas",
              label: "Błędy aplikacji",
              to: {
                name: "app_errors_list"
              },
              permissions: ["app_error"]
            },
            {
              icon: "window-maximize",
              iconPack: "fas",
              label: "Role aplikacji",
              to: {
                name: "member_ships_list"
              },
              permissions: ["member_ship"]
            },
          ]
        }
      ];
    },
    allowedMenu() {
      return this.menu.filter(menu => this.isAllowed(menu.permissions));
    }
  },
  methods: {
    allowedChildren(children) {
      let filtered = children.filter(child => this.isAllowed(child.permissions));
      filtered = filtered.filter(el => {
        if (el.to.name === "attendance_register") {
          let allowed;
          if (!this.lastAttendanceDate) {
            allowed = true;
          } else {
            allowed = moment(this.lastAttendanceDate, "DD-MM-YYYY").isBefore(moment(), "day");
          }
          return allowed;
        }
        return true;
      });
      return filtered;
    },
    async checkLastAttendanceRegisterSave() {
      if (!this.lastAttendanceDate || !moment(this.lastAttendanceDate, "DD-MM-YYYY").isSame(moment(), "day")) {
        let {data} = await http.get("/statements/1");
        if (data.lastAttendanceRegisterSave) {
          this.lastAttendanceDate = data.lastAttendanceRegisterSave;
        }
      }
    }
  },
  watch: {
    "$route.path": "checkLastAttendanceRegisterSave"
  },
  created() {
    this.checkLastAttendanceRegisterSave();
  }
};
</script>
